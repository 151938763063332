import dayjs from 'dayjs';
import currencyMixin from './currencyMixin';
export default {
    mixins: [currencyMixin],
    data() {
        return {
            diffoHeaders: [
                'driverName',
                'amountOfshifts',
                'employeeNumber',
                'compensateHours',
                'otherPaidAbsence',
                'unpaidLeave',
                'sickLeave',
                'accidentCompensationHours',
                'childIllness',
                'parentalLeave',
                'training',
                'militaryTraining',
                'layOff',
                'contract',
                'eventDetails',
                'vacationdays',
                'fullDomesticDailyAllowance',
                'halfDomesticDailyAllowance',
                'increasedDomesticFullDailyAllowance',
                'fullAbroadDailyAllowance',
                'halfAbroadDailyAllowance',
                'increasedAbroadFullDailyAllowance',
                'overTime50Percent',
                'overtime100Percent',
                'eveningSupplementHours',
                'nightSupplementHours',
                'sundayOrBankSupplementHours',
                'totalHours',
                'guaranteedHours',
                'workingTime',
                'waitingHours',
                'waitingInstances',
                'queuingHours',
                'queueingInstances',
                'paidBreakHours',
                'paidBreakInstances',
                'breakHours',
                'breakInstances',
                'drivingInstances',
                'loadingInstances',
                'unloadingInstances',
                'refuelingInstances',
                'maintenanceInstances',
                'maintenanceHours',
                'otherInstances',
                'adrHours',
                'craneHours',
                'dirtyJobHours',
                'hctHours',
                'dayOffBonus',
            ],
            displayHeaders: {
                shifts: [
                    'date',
                    'name',
                    'employeeNumber',
                    'salaryTypeId',
                    'salaryTypeName',
                    'amount',
                    'pieces',
                    'routeCode',
                    'route',
                    'vehicleCode',
                ],
                contracts: [
                    'date',
                    'name',
                    'employeeNumber',
                    'salaryTypeId',
                    'salaryTypeName',
                    'amount',
                    'pieces',
                    'routeCode',
                    'route',
                    'vehicleCode',
                ],
                vacations: [
                    'startDate',
                    'endDate',
                    'employeeNumber',
                    'name',
                    'salaryTypeId',
                ],
                leaves: [
                    'startDate',
                    'endDate',
                    'employeeNumber',
                    'name',
                    'salaryTypeId',
                    'pieces',
                ],
            },
            externalPayrollTypes: [
                {
                    value: 1,
                    name: 'Mepco',
                    beta: false,
                    categorized: true,
                    shifts: [
                        'date',
                        'name',
                        'employeeNumber',
                        'salaryTypeId',
                        'amount',
                        'pieces',
                        'routeCode',
                        'vehicleCode',
                    ],
                    contracts: [
                        'date',
                        'name',
                        'employeeNumber',
                        'salaryTypeId',
                        'amount',
                        'pieces',
                        'routeCode',
                        'vehicleCode',
                    ],
                    vacations: [
                        'employeeNumber',
                        'name',
                        'startDate',
                        'endDate',
                        'salaryTypeId',
                    ],
                    leaves: [
                        'employeeNumber',
                        'name',
                        'startDate',
                        'endDate',
                        'salaryTypeId',
                        'pieces',
                    ],
                    headerRow: null,
                    dateFormat: 'DD.MM.YYYY',
                },
                {
                    value: 2,
                    name: 'Fennoa',
                    beta: false,
                    categorized: false,
                    shifts: [
                        'employeeNumber',
                        'salaryTypeId',
                        'date',
                        'pieces',
                        'price',
                        'multiplier',
                        'description',
                        'startDate',
                        'endDate',
                        'absences',
                        'routeCode',
                    ],
                    contracts: null,
                    vacations: null,
                    leaves: null,
                    headerRow: null,
                    dateFormat: 'DD.MM.YYYY',
                },
                {
                    value: 3,
                    name: 'Netvisor',
                    beta: true,
                    categorized: false,
                    shifts: [
                        '',
                        'employeeNumber',
                        'date',
                        'salaryTypeId',
                        'pieces',
                        'description',
                        '',
                        'vehicleCode',
                        'routeCode',
                    ],
                    contracts: null,
                    vacations: null,
                    leaves: null,
                    headerRow: null,
                    dateFormat: 'DD.MM.YYYY',
                },
                {
                    value: 4,
                    name: 'Palkka.fi',
                    beta: true,
                    categorized: false,
                    shifts: [
                        'employeeNumber',
                        'periodStart',
                        'periodEnd',
                        'payDay',
                        'salaryTypeId',
                        'pieces',
                    ],
                    contracts: null,
                    vacations: null,
                    leaves: null,
                    headerRow: [
                        'Henkilötunnus',
                        'Kauden alkupvm',
                        'Kauden päät.pvm',
                        'Maksupvm',
                        'Palkkalaji',
                        'Määrä',
                        'A-hinta',
                        'Summa',
                    ],
                    dateFormat: 'DD.MM.YYYY',
                },
                {
                    value: 5,
                    name: 'Maestro',
                    beta: true,
                    categorized: false,
                    shifts: null,
                    contracts: null,
                    vacations: null,
                    leaves: null,
                    headerRow: null,
                    dateFormat: null,
                },
                {
                    value: 6,
                    name: 'Lemonsoft',
                    beta: true,
                    categorized: false,
                    shifts: [
                        'employeeNumber',
                        'date',
                        '', // Worktime_state
                        'pieces',
                        '', // Worktime_header
                        'description',
                        '', // Worktime_workshift
                        'salaryTypeId',
                        'vehicleCode',
                        '', // Project_phase_id
                        'routeCode',
                        '', // Worktime_invoice_bit
                        '', // Invoice_number
                        '', // Worktime_reason
                        '', // Worktime_unitprice,
                        'amount',
                        '', // Worktime_totalsum
                        '', // FormOfSalary_contract
                    ],
                    contracts: null,
                    vacations: null,
                    leaves: null,
                    headerRow: [
                        'Person_number',
                        'Worktime_date',
                        'Worktime_state',
                        'Worktime_hours',
                        'Worktime_header',
                        'Worktime_description',
                        'Worktime_workshift',
                        'Formofsalary_number',
                        'Project_number',
                        'Project_phase_id',
                        'Worktime_costcenter',
                        'Worktime_invoice_bit',
                        'Invoice_number',
                        'Worktime_reason',
                        'Worktime_unitprice',
                        'Worktime_unitamount',
                        'Worktime_totalsum',
                        'FormOfSalary_contract',
                    ],
                    dateFormat: 'YYYY-MM-DD',
                },
            ],
        };
    },
    methods: {
        getPayrollTypeByValue(val) {
            return this.externalPayrollTypes.find(({ value }) => value === val);
        },
        generateMaestroData(data) {
            const dataArr = data.map((d) => this.generateMaestroRow(d));
            return dataArr.join('\r\n');
        },
        generateMaestroRow(dataRow) {
            // Abandon all hope ye who enter here
            // https://maestro10.helpdocsonline.com/maestron-palkkaliittyma
            let {
                employeeNumber,
                salaryTypeId,
                pieces,
                amount,
                routeCode,
                vehicleCode,
                date,
            } = dataRow;

            // Bail out if any of these codes are longer than their max width or if required fields are missing
            if (
                !employeeNumber ||
                employeeNumber.length > 5 ||
                !salaryTypeId ||
                salaryTypeId.length > 4 ||
                (routeCode && routeCode.length > 4) ||
                (vehicleCode && vehicleCode.length > 3)
            )
                return 'INVALID DATA';

            // Make sure that values are of required length, format pieces and amount
            employeeNumber = this.$filters.pad(employeeNumber, 5);
            salaryTypeId = this.$filters.pad(employeeNumber, 4);
            routeCode = routeCode
                ? this.$filters.pad(employeeNumber, 4)
                : '0000';
            vehicleCode = vehicleCode
                ? this.$filters.pad(employeeNumber, 3)
                : '000';
            pieces = pieces
                ? this.$filters.pad((pieces.toFixed(2) * 100).toString(), 8)
                : '00000000';
            amount = amount
                ? this.$filters.pad((amount.toFixed(2) * 100).toString(), 10)
                : '0000000000';
            date = dayjs(
                this.$filters.formatIsoToUtc(date || dataRow.startDate),
            ).format('DD.MM.YYYY');

            const currency =
                this.currencies.find(
                    ({ symbol }) => symbol === this.$i18n.currency,
                )?.iso || 'EUR';

            return (
                employeeNumber +
                date +
                salaryTypeId +
                amount +
                pieces +
                routeCode +
                vehicleCode +
                currency
            );
        },
    },
};
